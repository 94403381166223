import { Spinner } from '@trmediaab/zebra-icons';
import PropTypes from 'prop-types';

import ErrorMessage from '../ErrorMessage';
import { RedirectToLogin } from '../RedirectToLogin';
import useApolloQuery from './useApolloQuery';
import useTraisQuery from './useTraisQuery';
import useVercelQuery from './useVercelQuery';

const identity = value => value;

const placeholders = {
  firstLoad: <Spinner size="48px" color="greys.3" />,
  spinner: <Spinner size="32px" color="greys.3" />,
  labelSibling: <Spinner size="24px" mr="2" color="greys.3" />,
};

const apiMap = {
  trhub: useApolloQuery,
  trais: useTraisQuery,
  vercel: useVercelQuery,
};

const Query = ({
  query,
  options,
  pageSize,
  render,
  placeholder,
  renderError,
  wrap,
  apiName,
}) => {
  const useQuery = apiMap[apiName];

  if ((apiName === 'trais' || apiName === 'vercel') && pageSize != null) {
    throw new Error(`Paging with ${apiName} api is not implemented`);
  }

  const { data, error, loading, ...queryRest } = useQuery(
    query,
    options,
    pageSize,
  );

  if (loading) {
    const component = placeholders[placeholder];
    return wrap(component, 'loading', undefined, queryRest);
  }

  let errorComponent;

  if (
    apiName === 'trhub' &&
    [error, error?.networkError, queryRest.fetchNextError?.networkError].some(
      err => err && err.statusCode === 401,
    )
  ) {
    errorComponent = (
      <RedirectToLogin loadingComponent={placeholders[placeholder]} />
    );
  } else if (error != null) {
    errorComponent = <ErrorMessage error={error} />;
  }

  if (errorComponent != null) {
    return wrap(
      renderError(errorComponent, error),
      'error',
      undefined,
      queryRest,
    );
  }

  return wrap(render(data, queryRest), 'data', data, queryRest);
};

Query.propTypes = {
  query: PropTypes.object.isRequired,
  options: PropTypes.object,
  pageSize: PropTypes.number,
  render: PropTypes.func.isRequired,
  renderError: PropTypes.func,
  placeholder: PropTypes.oneOf(Object.keys(placeholders)),
  wrap: PropTypes.func,
  apiName: PropTypes.oneOf(['trhub', 'trais', 'vercel']),
};

Query.defaultProps = {
  placeholder: 'spinner',
  renderError: identity,
  wrap: identity,
  apiName: 'trhub',
};

export default Query;
