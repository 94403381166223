import { Button } from '@trmediaab/zebra';

const LogoutButton = () => (
  <Button
    href={`${process.env.NEXT_PUBLIC_AUTH_URL}/logout?ReturnUrl=${encodeURI(
      window.location.href,
    )}`}
    bc="outline"
    width="100%"
  >
    Logga ut
  </Button>
);

export default LogoutButton;
