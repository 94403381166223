import { useSafeHtml } from '@trmediaab/zebra-hooks';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';

import DefaultRenderer from './DefaultRenderer';

/**
 *
 * Make internal links inside a container element navigate
 * with Next's router instead of causing a page refresh.
 *
 */
const useCaptureInternalLinks = () => {
  const { push } = useRouter();
  const [internalLinkElements, setInternalLinkElements] = useState([]);

  const handler = useCallback(
    e => {
      const parent = e.currentTarget;

      const elements = [...parent.querySelectorAll('a')]
        .filter(el =>
          el.href.startsWith(process.env.NEXT_PUBLIC_CANONICAL_ROOT),
        )
        .filter(el => !internalLinkElements.includes(el));

      elements.forEach(el =>
        el.addEventListener('click', e => {
          e.preventDefault();
          const href = el.href.replace(
            process.env.NEXT_PUBLIC_CANONICAL_ROOT,
            '',
          );
          push(href);
        }),
      );

      setInternalLinkElements(prev => [...prev, ...elements]);
    },
    [push, internalLinkElements],
  );

  return handler;
};

const Html = ({ html, render, ...props }) => {
  const sanitizedHtml = useSafeHtml(html);
  const clickHandler = useCaptureInternalLinks();

  return render({
    dangerouslySetInnerHTML: {
      __html: sanitizedHtml,
    },
    onClickCapture: clickHandler,
    ...props,
  });
};

Html.defaultProps = {
  render: DefaultRenderer,
};

Html.propTypes = {
  html: PropTypes.string.isRequired,
  render: PropTypes.func,
};

export default Html;
