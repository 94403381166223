import { Error as MaterialError } from '@styled-icons/material';
import { createStyledIcon } from '@trmediaab/zebra-icons';

const Error = createStyledIcon(MaterialError);

Error.defaultProps = {
  color: 'state.error',
  size: '14px',
};

export default Error;
